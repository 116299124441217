

import DxPieChart, {
  DxSeries,
  DxLegend,
  DxLabel,
  DxConnector,
  DxFont,
  DxExport,
} from 'devextreme-vue/pie-chart';

//import { dataSource } from './data.js';
import ReporteService from '../services/reportes-service';

export default {
  components: {
    DxPieChart,
    DxSeries,
    DxLegend,
    DxLabel,
    DxConnector,
    DxFont,
    DxExport,
  },
  data() {
    return {
      datasourceReporte:null,
    };
  },
  created() {
    this.reporteService = new ReporteService();
  },
  mounted() {
   
    this.reporteService.getAvanceListadoInformacionKPI().then(data => this.datasourceReporte = data);
    
  },
  methods: {
    formatLabel(pointInfo) {
      return `${pointInfo.valueText} (${pointInfo.percentText})`;
    },
  },
};
